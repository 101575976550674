



















































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { PayloadState } from '@/types/types';
import { loadWidget, findDistinctNetworks } from '@/utils/helpers';

@Component({
  name: 'LogDetailsBottomDrawerDialog',
  components: {
    EmptyPage: loadWidget('pages/EmptyPage'),
  },
  filters: {
    findDistinctNetworks,
  },
})
export default class LogDetailsBottomDrawerDialog extends Vue {
  @Prop({ required: true, default: false }) state!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: null }) details!: any;
  headers: { text: string; value: string; align?: string }[] = [
    {
      text: 'ID',
      value: 'messageId',
    },
    {
      text: 'Status Code',
      value: 'statusCode',
    },
    {
      text: 'Status Message',
      value: 'statusText',
    },
    {
      text: 'Error Code',
      value: 'errorCode',
    },

    {
      text: 'Error Message',
      value: 'errorMessage',
    },
    {
      text: 'From',
      value: 'from',
    },
    {
      text: 'To',
      value: 'to',
    },
  ];
  statusColors: { [key: string]: { bg: string; text: string } } = {
    pending: {
      bg: 'rgba(253, 183, 132, 0.41)',
      text: 'rgb(202, 133, 0)',
    },
    failed: {
      bg: 'rgba(251, 107, 92, 0.41)',
      text: 'rgb(240, 43, 22)',
    },
    success: { bg: 'rgba(65, 253, 77, 0.41)', text: 'rgb(7, 143, 60)' },
  };

  @Emit('actions')
  close(state: PayloadState): PayloadState {
    return state;
  }

  @Emit('delete')
  action(): { loading: PayloadState } {
    return {
      loading: {
        state: false,
        idx: 'details',
      },
    };
  }
}
